import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import SEO from "../components/SEO"
import linkResolver from "../utils/linkResolver"
import Trial from "../components/trial"

SwiperCore.use([Navigation])

const FuncItem = ({ item }) => {
  return (
    <div
      key={item.func_id}
      className="column is-3-desktop is-4-tablet px-5 py-6"
    >
      {item.func_image && (
        <figure className="image mb-5">
          <Image sharp={item.func_imageSharp} image={item.func_image} />
        </figure>
      )}
      {item.func_name && (
        <h4 className="title is-size-5">{RichText.asText(item.func_name)}</h4>
      )}
      {item.func_description &&
        RichText.render(item.func_description, linkResolver)}
    </div>
  )
}

const SolutionsPage = ({
  data,
  pageContext: { locale, uid, alternateLanguages },
  location,
}) => {
  const SolutionsPageData = data.prismic.solutionspage
  const AllUseCaseData = data.prismic.allUsecasespages.edges[0].node.use_cases
  const [selectedCategory, setSelectedCategory] = useState(false)
  const [searchResults, setSearchResults] = useState(false)
  const mapFunc = []
  let allFunctionalities = []
  let allUseCases = []
  let funcByUseCases = {}

  // filter For Categories
  SolutionsPageData.body.map(cat => {
    cat.fields.map(func => {
      // To avoid doublons, we use array mapFunc To keep track of the ids tha already exists
      if (!mapFunc.includes(func.func_id)) {
        mapFunc.push(func.func_id)
        allFunctionalities.push(func)
      }
    })
  })

  // filters For UseCases
  allFunctionalities.map(func => {
    if (!func.func_use_case_id) return false
    func.func_use_case_id.split(",").map(useCase => {
      useCase = useCase.trim()
      // creating the filter's button for usecases row
      if (!allUseCases.includes(useCase)) allUseCases.push(useCase)

      // creating arrays based on useCase
      funcByUseCases[useCase]
        ? funcByUseCases[useCase].push(func) //addind one more
        : (funcByUseCases[useCase] = [func]) //init
    })
  })

  // Map Usescase Titles from AllUseCaseData
  let allUseCaseTitles = {}
  AllUseCaseData.map(useCaseTitle => {
    return (allUseCaseTitles[useCaseTitle.use_case_id] =
      useCaseTitle.use_case_title[0].text)
  })

  const handleCategoryChange = (selectedFilter, isUseCase = false) => {
    setSearchResults(false)
    isUseCase !== false
      ? setSelectedCategory({
          fields: funcByUseCases[selectedFilter],
          useCaseSelected: selectedFilter,
        })
      : setSelectedCategory(selectedFilter)
  }

  const handleSearchChange = e => {
    // filter with Name OR Id
    let results = allFunctionalities.filter(
      func =>
        func.func_name[0].text
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        func.func_id.includes(e.target.value.toLowerCase())
    )
    setSearchResults({ fields: results })
    setSelectedCategory(false) // Displayed by serach results not Categories, All button is activated
    // console.log(results)
  }

  return (
    <>
      <SEO
        title={SolutionsPageData.meta_title}
        desc={SolutionsPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />

      <section className="section is-theme2" css={solutionsCss}>
        <div className="container">
          <div className="columns is-centered mb-6">
            <div className="column is-10">
              <h1 className="title has-text-centered has-text-weight-medium pb-4">
                {RichText.asText(SolutionsPageData.title)}
              </h1>
              <h2 className="subtitle has-text-centered">
                {RichText.asText(SolutionsPageData.subtitle)}
              </h2>
              <div className="field">
                <p className="control has-icons-left has-icons-right">
                  <label className="is-hidden" htmlFor="search">
                    Search
                  </label>
                  <input
                    className="input is-rounded"
                    type="text"
                    name="search"
                    id="search"
                    placeholder={SolutionsPageData.search_placeholder}
                    onChange={handleSearchChange}
                  />
                  <span className="icon is-left">
                    <i className="icon-magnifying_glass"></i>
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="buttons is-centered">
            <div className="slider-main is-flex" style={{ width: "100%" }}>
              <Swiper
                css={SwiperCss}
                slidesPerView={"auto"}
                loop={true}
                loopedSlides={3}
                centeredSlides={true}
                navigation={{
                  nextEl: ".swiper-features-button-next",
                  prevEl: ".swiper-features-button-prev",
                }}
                threshold={20}
              >
                <SwiperSlide key={"all"}>
                  <a
                    onClick={() => handleCategoryChange(false)}
                    className={`button is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border py-2 px-4 mx-4 ${
                      !selectedCategory ? "is-active" : ""
                    }`}
                  >
                    <span className="icon is-size-5 pl-2 pr-1">
                      <i className={`icon-all`}></i>
                    </span>
                    <h3>{SolutionsPageData.all_button}</h3>
                  </a>
                </SwiperSlide>

                {SolutionsPageData.body.map(cat => {
                  return (
                    <SwiperSlide key={cat.primary.category_id}>
                      {cat.primary.category_image && (
                        <a
                          onClick={() => handleCategoryChange(cat)}
                          className={`button is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border py-2 px-4 mx-4 ${
                            selectedCategory.primary &&
                            cat.primary.category_id ===
                              selectedCategory.primary.category_id
                              ? "is-active"
                              : ""
                          }`}
                        >
                          <span className="icon is-size-5 pl-2 pr-1">
                            <i
                              className={`icon-${cat.primary.category_id.toLowerCase()}`}
                            ></i>
                          </span>
                          <span>
                            {RichText.asText(cat.primary.category_name)}
                          </span>
                        </a>
                      )}
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <div
                role="button"
                name="Previous Slide"
                aria-label="Previous slide"
                className="swiper-button-prev swiper-features-button-prev"
              ></div>
              <div
                role="button"
                name="Next Slide"
                aria-label="Next Slide"
                className="swiper-button-next swiper-features-button-next"
              ></div>
            </div>
          </div>
          {/* Use Cases Filters  */}
          <div className="buttons is-centered">
            <div className="slider-main is-flex" style={{ width: "100%" }}>
              <Swiper
                css={SwiperCss}
                slidesPerView={"auto"}
                loop
                centeredSlides={true}
                navigation={{
                  nextEl: ".swiper-case-button-next",
                  prevEl: ".swiper-case-button-prev",
                }}
                threshold={20}
              >
                <SwiperSlide key={"all"}>
                  <a
                    onClick={() => handleCategoryChange(false)}
                    className={`button is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border py-2 px-4 mx-4 ${
                      !selectedCategory ? "is-active" : ""
                    }`}
                  >
                    <span className="icon is-size-5 pl-2 pr-1">
                      <i className={`icon-all`}></i>
                    </span>
                    <h3>{SolutionsPageData.all_button}</h3>
                  </a>
                </SwiperSlide>

                {allUseCases.map(useCase => {
                  return (
                    <SwiperSlide key={useCase}>
                      <a
                        onClick={() => handleCategoryChange(useCase, true)}
                        className={`button is-rounded has-background-white has-text-primary has-text-weight-medium button__gradient-border py-2 px-4 mx-4 
                          ${
                            useCase === selectedCategory.useCaseSelected
                              ? "is-active"
                              : ""
                          }
                          `}
                      >
                        <span className="icon is-size-5 pl-2 pr-1">
                          <i className={`icon-${useCase.toLowerCase()}`}></i>
                        </span>
                        <span>
                          {allUseCaseTitles[useCase]}
                          {/* {RichText.asText(cat.primary.category_name)} */}
                        </span>
                      </a>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <div
                role="button"
                name="Previous Slide"
                className="swiper-button-prev swiper-case-button-prev"
              ></div>
              <div
                role="button"
                name="Next Slide"
                className="swiper-button-next swiper-case-button-next"
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" css={solutionItemsCss}>
        <div className="container">
          <div className="columns is-multiline">
            {!selectedCategory &&
              !searchResults &&
              allFunctionalities.map((func, i) => (
                <FuncItem key={`all-${func.func_id}-${i}`} item={func} />
              ))}
            {selectedCategory.fields &&
              !searchResults &&
              selectedCategory.fields.map((func, i) => {
                return <FuncItem key={`cat-${func.func_id}-${i}`} item={func} />
              })}
            {searchResults.fields &&
              searchResults.fields.map((func, i) => {
                return (
                  <FuncItem key={`search-${func.func_id}-${i}`} item={func} />
                )
              })}
          </div>
        </div>
      </section>

      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
    </>
  )
}

export default SolutionsPage

SolutionsPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const solutionsQuery = graphql`
  query SolutionsQuery($uid: String!, $locale: String!) {
    prismic {
      solutionspage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        search_placeholder
        all_button
        body {
          ... on PRISMIC_SolutionspageBodyFonction {
            primary {
              category_id
              category_name
              category_image
              category_imageSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            fields {
              func_id
              func_name
              func_description
              func_use_case_id
              func_image
              func_imageSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }

      allUsecasespages(lang: $locale) {
        edges {
          node {
            use_cases {
              use_case_id
              use_case_title
            }
          }
        }
      }

      ...Trial
    }
    site {
      ...SiteInformation
    }
  }
`

const solutionsCss = css`
  .control .icon {
    color: ${colors.blue};
  }

  .input {
    border-color: ${colors.borderForm};
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 0.5;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 0.5;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    opacity: 0.5;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    opacity: 0.5;
  }

  .button {
    margin-bottom: 0;

    &:hover {
      span {
        color: #fff;
      }
    }
  }
`

const solutionItemsCss = css`
  img {
    height: 4rem;
  }

  .image {
    display: flex;
    align-items: flex-end;
  }
`

const SwiperCss = css`
  .swiper-slide {
    width: auto;
    .button {
      height: 20px;
      padding: 5px 10px;
    }
  }
`
